<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">년도</div>
            <ul class="content">
              <li class="item" style="width: 90px;">
                <input-number
                  ref="year"
                  v-model="searchOptions.year"
                  :allowDot="false"
                  :allowMinus="false"
                  :displayComma="false"
                  :propMaxLength="4"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              v-on:click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true"/>
      <article class="body-article">
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">월별 회원종류별 예약현황</div>
              <div v-show="titleYear" class="header-caption">[영업년도 : {{titleYear}}년도]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="PRINT"
                      @click.native="print"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      >인쇄
                  </erp-button>

                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="bodyGrid"
                v-bind="bodyGridProps"
                :dataSource="monthlyReservationStatusByMemberTypes"
                :aggregates="bodyGridAggregates"
                @headerCellInfo="onBodyGridHeaderCellInfo"
                @queryCellInfo="onBodyGridQueryCellInfo"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>
</template>

<style scoped>
body .appContent .body-article .section-body {overflow: hidden; border: none;}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton.vue";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputNumber from "@/components/common/InputNumber";
import ReportView from '@/components/common/report/ReportView';
import {
  Aggregate,
  Resize,
  ForeignKey,
  ExcelExport,
  Page,
  Edit,
  Group,
  Filter,
} from "@syncfusion/ej2-vue-grids";
import GolfErpAPI from "@/api/v2/GolfErpAPI";

import {numberWithCommas} from "@/utils/number";
import {getEndOfMonth, getTodayNavigationDate} from "@/utils/date";
import {
  commonCodesGetCommonCode,
  commonCodesGetCommonCodeByIdx,
} from "@/utils/commonCodes";
import {mapGetters} from "vuex";

export default {
  name: "MonthlyReservationStatusByMemberType",
  components: {
    EjsGridWrapper,
    InputNumber,
    ReportView,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin],
  data() {
    return {
      searchOptions: {
        year: null,
      },
      titleYear: null,
      monthlyReservationStatusByMemberTypes: [],
      monthlyReservationStatusByMemberTypeLength: 0,
      monthlyReservationStatusByMemberTypesByTotal: {
        weekdayMonth01: 0,
        weekdayMonth02: 0,
        weekdayMonth03: 0,
        weekdayMonth04: 0,
        weekdayMonth05: 0,
        weekdayMonth06: 0,
        weekdayMonth07: 0,
        weekdayMonth08: 0,
        weekdayMonth09: 0,
        weekdayMonth10: 0,
        weekdayMonth11: 0,
        weekdayMonth12: 0,
        weekendMonth01: 0,
        weekendMonth02: 0,
        weekendMonth03: 0,
        weekendMonth04: 0,
        weekendMonth05: 0,
        weekendMonth06: 0,
        weekendMonth07: 0,
        weekendMonth08: 0,
        weekendMonth09: 0,
        weekendMonth10: 0,
        weekendMonth11: 0,
        weekendMonth12: 0,
        totalMonth01: 0,
        totalMonth02: 0,
        totalMonth03: 0,
        totalMonth04: 0,
        totalMonth05: 0,
        totalMonth06: 0,
        totalMonth07: 0,
        totalMonth08: 0,
        totalMonth09: 0,
        totalMonth10: 0,
        totalMonth11: 0,
        totalMonth12: 0,
        firstHalfWeekday: 0,
        firstHalfWeekend: 0,
        firstHalfTotal: 0,
        secondHalfWeekday: 0,
        secondHalfWeekend: 0,
        secondHalfTotal: 0,
        weekdayTotal: 0,
        weekendTotal: 0,
        total: 0,
      },
    };
  },
  async created() {
    this.searchOptions.year = await getTodayNavigationDate("YYYY");
  },
  destroyed() {},
  computed: {
    ...mapGetters(['username']),
    isPopupOpened() {
      return false;
    },
    bodyGridProps() {
      return {
        editSettings: {
          allowEditing: false,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        selectionSettings: {
          type: "Single", mode: "Both", enableToggle: false
        },
        groupSettings: {
          columns: ["partDiv"],
          showDropArea: false,
        },
        provides: [
          Aggregate,
          Edit,
          ForeignKey,
          Group,
          Resize,
          Filter,
          Page,
          ExcelExport,
        ],
        allowSorting: false,
        allowFiltering: false,
        allowGrouping: true,
        allowExcelExport: true,
        noColumnWidth: 34,
        columns: [
          {
            field: "partDiv",
            visible: false,
          },
          {
            field: "partDivName",
            headerText: "부별",
            type: "string",
            minWidth: 16,
            width: 42,
            textAlign: "center",
          },
          {
            field: "memberDivAttrb",
            headerText: "회원종류",
            type: "string",
            minWidth: 16,
            width: 70,
            textAlign: "center",
          },
          {
            headerText: "1월",
            columns: [
              {
                field: "weekdayMonth01",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
              {
                field: "weekendMonth01",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
              {
                field: "totalMonth01",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "2월",
            columns: [
              {
                field: "weekdayMonth02",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
              {
                field: "weekendMonth02",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
              {
                field: "totalMonth02",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "3월",
            columns: [
              {
                field: "weekdayMonth03",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
              {
                field: "weekendMonth03",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
              {
                field: "totalMonth03",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "4월",
            columns: [
              {
                field: "weekdayMonth04",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
              {
                field: "weekendMonth04",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
              {
                field: "totalMonth04",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "5월",
            columns: [
              {
                field: "weekdayMonth05",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
              {
                field: "weekendMonth05",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
              {
                field: "totalMonth05",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "6월",
            columns: [
              {
                field: "weekdayMonth06",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
              {
                field: "weekendMonth06",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
              {
                field: "totalMonth06",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "상반기",
            columns: [
              {
                field: "firstHalfWeekday",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 60,
                textAlign: "right",
              },
              {
                field: "firstHalfWeekend",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 60,
                textAlign: "right",
              },
              {
                field: "firstHalfTotal",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 60,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "7월",
            columns: [
              {
                field: "weekdayMonth07",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
              {
                field: "weekendMonth07",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
              {
                field: "totalMonth07",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "8월",
            columns: [
              {
                field: "weekdayMonth08",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
              {
                field: "weekendMonth08",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
              {
                field: "totalMonth08",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "9월",
            columns: [
              {
                field: "weekdayMonth09",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
              {
                field: "weekendMonth09",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
              {
                field: "totalMonth09",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "10월",
            columns: [
              {
                field: "weekdayMonth10",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
              {
                field: "weekendMonth10",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
              {
                field: "totalMonth10",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "11월",
            columns: [
              {
                field: "weekdayMonth11",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
              {
                field: "weekendMonth11",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
              {
                field: "totalMonth11",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "12월",
            columns: [
              {
                field: "weekdayMonth12",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
              {
                field: "weekendMonth12",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
              {
                field: "totalMonth12",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 50,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "하반기",
            columns: [
              {
                field: "secondHalfWeekday",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 60,
                textAlign: "right",
              },
              {
                field: "secondHalfWeekend",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 60,
                textAlign: "right",
              },
              {
                field: "secondHalfTotal",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 60,
                textAlign: "right",
              },
            ],
          },
          {
            headerText: "합계",
            columns: [
              {
                field: "weekdayTotal",
                headerText: "주중",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 60,
                textAlign: "right",
              },
              {
                field: "weekendTotal",
                headerText: "주말",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 60,
                textAlign: "right",
              },
              {
                field: "total",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 60,
                textAlign: "right",
              },
            ],
          },
        ],
      };
    },
    bodyGridAggregates() {
      return [
        {
          columns: [
            {
              field: "memberDivAttrb",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items[0]?.partDivName + " 소계";
              },
            },
            {
              field: "weekdayMonth01",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.weekdayMonth01).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "weekdayMonth02",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.weekdayMonth02).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "weekdayMonth03",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.weekdayMonth03).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "weekdayMonth04",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.weekdayMonth04).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "weekdayMonth05",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.weekdayMonth05).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "weekdayMonth06",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.weekdayMonth06).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "weekdayMonth07",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.weekdayMonth07).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "weekdayMonth08",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.weekdayMonth08).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "weekdayMonth09",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.weekdayMonth09).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "weekdayMonth10",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.weekdayMonth10).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "weekdayMonth11",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.weekdayMonth11).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "weekdayMonth12",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.weekdayMonth12).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "weekendMonth01",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.weekendMonth01).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "weekendMonth02",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.weekendMonth02).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "weekendMonth03",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.weekendMonth03).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "weekendMonth04",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.weekendMonth04).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "weekendMonth05",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.weekendMonth05).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "weekendMonth06",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.weekendMonth06).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "weekendMonth07",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.weekendMonth07).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "weekendMonth08",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.weekendMonth08).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "weekendMonth09",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.weekendMonth09).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "weekendMonth10",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.weekendMonth10).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "weekendMonth11",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.weekendMonth11).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "weekendMonth12",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.weekendMonth12).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "totalMonth01",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.totalMonth01).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "totalMonth02",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.totalMonth02).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "totalMonth03",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.totalMonth03).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "totalMonth04",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.totalMonth04).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "totalMonth05",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.totalMonth05).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "totalMonth06",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.totalMonth06).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "totalMonth07",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.totalMonth07).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "totalMonth08",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.totalMonth08).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "totalMonth09",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.totalMonth09).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "totalMonth10",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.totalMonth10).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "totalMonth11",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.totalMonth11).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "totalMonth12",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.totalMonth12).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "firstHalfWeekday",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.firstHalfWeekday).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "firstHalfWeekend",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.firstHalfWeekend).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "firstHalfTotal",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.firstHalfTotal).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "secondHalfWeekday",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.secondHalfWeekday).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "secondHalfWeekend",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.secondHalfWeekend).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "secondHalfTotal",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.secondHalfTotal).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "weekdayTotal",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.weekdayTotal).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "weekendTotal",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.weekendTotal).reduce((acc, cur) => acc + cur, 0);
              },
            },
            {
              field: "total",
              aggregationType: "GroupCaption",
              customAggregate: (args) => {
                return args?.items?.filter(item => !item.isPartDivNameVisible).map(item => item.total).reduce((acc, cur) => acc + cur, 0);
              },
            },
          ]
        },
        {
          columns: [
            {
              field: "memberDivAttrb",
              aggregationType: "TotalCaption",
              customAggregate: "합계",
            },
            {
              field: "weekdayMonth01",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.weekdayMonth01,
            },
            {
              field: "weekdayMonth02",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.weekdayMonth02,
            },
            {
              field: "weekdayMonth03",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.weekdayMonth03,
            },
            {
              field: "weekdayMonth04",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.weekdayMonth04,
            },
            {
              field: "weekdayMonth05",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.weekdayMonth05,
            },
            {
              field: "weekdayMonth06",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.weekdayMonth06,
            },
            {
              field: "weekdayMonth07",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.weekdayMonth07,
            },
            {
              field: "weekdayMonth08",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.weekdayMonth08,
            },
            {
              field: "weekdayMonth09",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.weekdayMonth09,
            },
            {
              field: "weekdayMonth10",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.weekdayMonth10,
            },
            {
              field: "weekdayMonth11",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.weekdayMonth11,
            },
            {
              field: "weekdayMonth12",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.weekdayMonth12,
            },
            {
              field: "weekendMonth01",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.weekendMonth01,
            },
            {
              field: "weekendMonth02",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.weekendMonth02,
            },
            {
              field: "weekendMonth03",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.weekendMonth03,
            },
            {
              field: "weekendMonth04",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.weekendMonth04,
            },
            {
              field: "weekendMonth05",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.weekendMonth05,
            },
            {
              field: "weekendMonth06",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.weekendMonth06,
            },
            {
              field: "weekendMonth07",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.weekendMonth07,
            },
            {
              field: "weekendMonth08",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.weekendMonth08,
            },
            {
              field: "weekendMonth09",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.weekendMonth09,
            },
            {
              field: "weekendMonth10",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.weekendMonth10,
            },
            {
              field: "weekendMonth11",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.weekendMonth11,
            },
            {
              field: "weekendMonth12",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.weekendMonth12,
            },
            {
              field: "totalMonth01",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.totalMonth01,
            },
            {
              field: "totalMonth02",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.totalMonth02,
            },
            {
              field: "totalMonth03",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.totalMonth03,
            },
            {
              field: "totalMonth04",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.totalMonth04,
            },
            {
              field: "totalMonth05",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.totalMonth05,
            },
            {
              field: "totalMonth06",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.totalMonth06,
            },
            {
              field: "totalMonth07",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.totalMonth07,
            },
            {
              field: "totalMonth08",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.totalMonth08,
            },
            {
              field: "totalMonth09",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.totalMonth09,
            },
            {
              field: "totalMonth10",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.totalMonth10,
            },
            {
              field: "totalMonth11",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.totalMonth11,
            },
            {
              field: "totalMonth12",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.totalMonth12,
            },
            {
              field: "firstHalfWeekday",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.firstHalfWeekday,
            },
            {
              field: "firstHalfWeekend",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.firstHalfWeekend,
            },
            {
              field: "firstHalfTotal",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.firstHalfTotal,
            },
            {
              field: "secondHalfWeekday",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.secondHalfWeekday,
            },
            {
              field: "secondHalfWeekend",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.secondHalfWeekend,
            },
            {
              field: "secondHalfTotal",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.secondHalfTotal,
            },
            {
              field: "weekdayTotal",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.weekdayTotal,
            },
            {
              field: "weekendTotal",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.weekendTotal,
            },
            {
              field: "total",
              aggregationType: "TotalCaption",
              customAggregate: this.monthlyReservationStatusByMemberTypesByTotal.total,
            },
          ]
        }
      ];
    },
  },
  mounted() {},
  methods: {
    numberWithCommas,
    async onViewButtonClicked() {
      const fromDate = this.searchOptions.year + "-01-01";
      const monthLastDay = await getEndOfMonth(Number(this.searchOptions.year), 12);
      const toDate = this.searchOptions.year + "-12-" + monthLastDay;

      const reservationInfos = await GolfErpAPI.fetchReservationInfo({
        fromDate: fromDate,
        toDate: toDate,
      });

      const partDivs = commonCodesGetCommonCode("PART_DIV");
      const months = ["01","02","03","04","05","06","07","08","09","10","11","12"];

      let memberDivAttrbIdx3 = [];
      let resveChannelAttrbIdx1 = [];

      commonCodesGetCommonCodeByIdx("MEMBER_DIV", 3).map(item => {
        item.comCodeAttrbList.map(attrb => {
          memberDivAttrbIdx3.push(attrb);
        });
      });
      commonCodesGetCommonCodeByIdx("RESVE_CHANNEL", 1).map(item => {
        item.comCodeAttrbList.map(attrb => {
          resveChannelAttrbIdx1.push(attrb);
        });
      });

      let monthlyReservationStatusByMemberTypes = [];

      const loopArr = [0,1,2,3,4];

      partDivs.forEach(partDiv => {
        loopArr.forEach(loop => {
          let obj = {
            partDiv: partDiv.comCode,
            partDivName: partDiv.comName,
            isPartDivNameVisible: loop === 0,
            memberDivAttrb: loop === 0 ? "예약팀수" : loop === 1 ? "회원" : loop === 2 ? "이용권" : loop === 3 ? "대행사" : loop === 4 ? "비회원" : "",
          };
          months.forEach(month => {
            obj["weekdayMonth" + month] =
                reservationInfos.filter(resveInfo =>
                    resveInfo.resveDate.substring(5, 7) === month &&
                    resveInfo.bsnCode === "WEEKDAY" &&
                    resveInfo.partDiv === partDiv.comCode &&
                    (
                        loop === 0 ?
                          true :
                          loop === 1 ?
                            resveChannelAttrbIdx1
                              .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                              .map(resveChannel => resveChannel.comCode)
                              .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                            memberDivAttrbIdx3
                              .filter(memberDivAttrb => memberDivAttrb.attrb === "MEMBER")
                              .map(memberDiv => memberDiv.comCode)
                              .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                            loop === 2 ?
                              resveChannelAttrbIdx1
                                .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                                .map(resveChannel => resveChannel.comCode)
                                .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                              memberDivAttrbIdx3
                                .filter(memberDivAttrb => memberDivAttrb.attrb === "COPR")
                                .map(memberDiv => memberDiv.comCode)
                                .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                              loop === 3 ?
                                resveChannelAttrbIdx1
                                  .filter(resveChannelAttrb => resveChannelAttrb.attrb === "AGENT")
                                  .map(resveChannel => resveChannel.comCode)
                                  .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) :
                                loop === 4 ?
                                  resveChannelAttrbIdx1
                                    .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                                    .map(resveChannel => resveChannel.comCode)
                                    .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                                  memberDivAttrbIdx3
                                    .filter(memberDivAttrb => (memberDivAttrb.attrb ? memberDivAttrb.attrb : "NOM") === "NOM")
                                    .map(memberDiv => memberDiv.comCode)
                                    .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                                  false
                    )
                ).length;
            obj["weekendMonth" + month] =
                reservationInfos.filter(resveInfo =>
                    resveInfo.resveDate.substring(5, 7) === month &&
                    resveInfo.bsnCode === "WEEKEND" &&
                    resveInfo.partDiv === partDiv.comCode &&
                    (
                      loop === 0 ?
                        true :
                        loop === 1 ?
                          resveChannelAttrbIdx1
                            .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                            .map(resveChannel => resveChannel.comCode)
                            .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                          memberDivAttrbIdx3
                            .filter(memberDivAttrb => memberDivAttrb.attrb === "MEMBER")
                            .map(memberDiv => memberDiv.comCode)
                            .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                          loop === 2 ?
                            resveChannelAttrbIdx1
                              .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                              .map(resveChannel => resveChannel.comCode)
                              .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                            memberDivAttrbIdx3
                              .filter(memberDivAttrb => memberDivAttrb.attrb === "COPR")
                              .map(memberDiv => memberDiv.comCode)
                              .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                            loop === 3 ?
                              resveChannelAttrbIdx1
                                .filter(resveChannelAttrb => resveChannelAttrb.attrb === "AGENT")
                                .map(resveChannel => resveChannel.comCode)
                                .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) :
                              loop === 4 ?
                                resveChannelAttrbIdx1
                                  .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                                  .map(resveChannel => resveChannel.comCode)
                                  .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                                memberDivAttrbIdx3
                                  .filter(memberDivAttrb => (memberDivAttrb.attrb ? memberDivAttrb.attrb : "NOM") === "NOM")
                                  .map(memberDiv => memberDiv.comCode)
                                  .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                                false
                    )
                ).length;
            obj["totalMonth" + month] =
                reservationInfos.filter(resveInfo =>
                    resveInfo.resveDate.substring(5, 7) === month &&
                    resveInfo.partDiv === partDiv.comCode &&
                    (
                      loop === 0 ?
                        true :
                        loop === 1 ?
                          resveChannelAttrbIdx1
                            .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                            .map(resveChannel => resveChannel.comCode)
                            .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                          memberDivAttrbIdx3
                            .filter(memberDivAttrb => memberDivAttrb.attrb === "MEMBER")
                            .map(memberDiv => memberDiv.comCode)
                            .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                          loop === 2 ?
                            resveChannelAttrbIdx1
                              .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                              .map(resveChannel => resveChannel.comCode)
                              .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                            memberDivAttrbIdx3
                              .filter(memberDivAttrb => memberDivAttrb.attrb === "COPR")
                              .map(memberDiv => memberDiv.comCode)
                              .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                            loop === 3 ?
                              resveChannelAttrbIdx1
                                .filter(resveChannelAttrb => resveChannelAttrb.attrb === "AGENT")
                                .map(resveChannel => resveChannel.comCode)
                                .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) :
                              loop === 4 ?
                                resveChannelAttrbIdx1
                                  .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                                  .map(resveChannel => resveChannel.comCode)
                                  .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                                memberDivAttrbIdx3
                                  .filter(memberDivAttrb => (memberDivAttrb.attrb ? memberDivAttrb.attrb : "NOM") === "NOM")
                                  .map(memberDiv => memberDiv.comCode)
                                  .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                                false
                    )
                ).length;
            obj["firstHalfWeekday"] =
                reservationInfos.filter(resveInfo =>
                    ["01","02","03","04","05","06"].includes(resveInfo.resveDate.substring(5, 7)) &&
                    resveInfo.bsnCode === "WEEKDAY" &&
                    resveInfo.partDiv === partDiv.comCode &&
                    (
                      loop === 0 ?
                        true :
                        loop === 1 ?
                          resveChannelAttrbIdx1
                            .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                            .map(resveChannel => resveChannel.comCode)
                            .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                          memberDivAttrbIdx3
                            .filter(memberDivAttrb => memberDivAttrb.attrb === "MEMBER")
                            .map(memberDiv => memberDiv.comCode)
                            .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                          loop === 2 ?
                            resveChannelAttrbIdx1
                              .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                              .map(resveChannel => resveChannel.comCode)
                              .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                            memberDivAttrbIdx3
                              .filter(memberDivAttrb => memberDivAttrb.attrb === "COPR")
                              .map(memberDiv => memberDiv.comCode)
                              .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                            loop === 3 ?
                              resveChannelAttrbIdx1
                                .filter(resveChannelAttrb => resveChannelAttrb.attrb === "AGENT")
                                .map(resveChannel => resveChannel.comCode)
                                .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) :
                              loop === 4 ?
                                resveChannelAttrbIdx1
                                  .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                                  .map(resveChannel => resveChannel.comCode)
                                  .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                                memberDivAttrbIdx3
                                  .filter(memberDivAttrb => (memberDivAttrb.attrb ? memberDivAttrb.attrb : "NOM") === "NOM")
                                  .map(memberDiv => memberDiv.comCode)
                                  .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                                false
                    )
                ).length;
            obj["firstHalfWeekend"] =
                reservationInfos.filter(resveInfo =>
                    ["01","02","03","04","05","06"].includes(resveInfo.resveDate.substring(5, 7)) &&
                    resveInfo.bsnCode === "WEEKEND" &&
                    resveInfo.partDiv === partDiv.comCode &&
                    (
                      loop === 0 ?
                        true :
                        loop === 1 ?
                          resveChannelAttrbIdx1
                            .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                            .map(resveChannel => resveChannel.comCode)
                            .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                          memberDivAttrbIdx3
                            .filter(memberDivAttrb => memberDivAttrb.attrb === "MEMBER")
                            .map(memberDiv => memberDiv.comCode)
                            .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                          loop === 2 ?
                            resveChannelAttrbIdx1
                              .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                              .map(resveChannel => resveChannel.comCode)
                              .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                            memberDivAttrbIdx3
                              .filter(memberDivAttrb => memberDivAttrb.attrb === "COPR")
                              .map(memberDiv => memberDiv.comCode)
                              .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                            loop === 3 ?
                              resveChannelAttrbIdx1
                                .filter(resveChannelAttrb => resveChannelAttrb.attrb === "AGENT")
                                .map(resveChannel => resveChannel.comCode)
                                .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) :
                              loop === 4 ?
                                resveChannelAttrbIdx1
                                  .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                                  .map(resveChannel => resveChannel.comCode)
                                  .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                                memberDivAttrbIdx3
                                  .filter(memberDivAttrb => (memberDivAttrb.attrb ? memberDivAttrb.attrb : "NOM") === "NOM")
                                  .map(memberDiv => memberDiv.comCode)
                                  .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                                false
                    )
                ).length;
            obj["firstHalfTotal"] =
                reservationInfos.filter(resveInfo =>
                    ["01","02","03","04","05","06"].includes(resveInfo.resveDate.substring(5, 7)) &&
                    resveInfo.partDiv === partDiv.comCode &&
                    (
                      loop === 0 ?
                        true :
                        loop === 1 ?
                          resveChannelAttrbIdx1
                            .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                            .map(resveChannel => resveChannel.comCode)
                            .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                          memberDivAttrbIdx3
                            .filter(memberDivAttrb => memberDivAttrb.attrb === "MEMBER")
                            .map(memberDiv => memberDiv.comCode)
                            .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                          loop === 2 ?
                            resveChannelAttrbIdx1
                              .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                              .map(resveChannel => resveChannel.comCode)
                              .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                            memberDivAttrbIdx3
                              .filter(memberDivAttrb => memberDivAttrb.attrb === "COPR")
                              .map(memberDiv => memberDiv.comCode)
                              .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                            loop === 3 ?
                              resveChannelAttrbIdx1
                                .filter(resveChannelAttrb => resveChannelAttrb.attrb === "AGENT")
                                .map(resveChannel => resveChannel.comCode)
                                .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) :
                              loop === 4 ?
                                resveChannelAttrbIdx1
                                  .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                                  .map(resveChannel => resveChannel.comCode)
                                  .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                                memberDivAttrbIdx3
                                  .filter(memberDivAttrb => (memberDivAttrb.attrb ? memberDivAttrb.attrb : "NOM") === "NOM")
                                  .map(memberDiv => memberDiv.comCode)
                                  .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                                false
                    )
                ).length;
            obj["secondHalfWeekday"] =
                reservationInfos.filter(resveInfo =>
                    ["07","08","09","10","11","12"].includes(resveInfo.resveDate.substring(5, 7)) &&
                    resveInfo.bsnCode === "WEEKDAY" &&
                    resveInfo.partDiv === partDiv.comCode &&
                    (
                      loop === 0 ?
                        true :
                        loop === 1 ?
                          resveChannelAttrbIdx1
                            .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                            .map(resveChannel => resveChannel.comCode)
                            .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                          memberDivAttrbIdx3
                            .filter(memberDivAttrb => memberDivAttrb.attrb === "MEMBER")
                            .map(memberDiv => memberDiv.comCode)
                            .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                          loop === 2 ?
                            resveChannelAttrbIdx1
                              .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                              .map(resveChannel => resveChannel.comCode)
                              .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                            memberDivAttrbIdx3
                              .filter(memberDivAttrb => memberDivAttrb.attrb === "COPR")
                              .map(memberDiv => memberDiv.comCode)
                              .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                            loop === 3 ?
                              resveChannelAttrbIdx1
                                .filter(resveChannelAttrb => resveChannelAttrb.attrb === "AGENT")
                                .map(resveChannel => resveChannel.comCode)
                                .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) :
                              loop === 4 ?
                                resveChannelAttrbIdx1
                                  .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                                  .map(resveChannel => resveChannel.comCode)
                                  .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                                memberDivAttrbIdx3
                                  .filter(memberDivAttrb => (memberDivAttrb.attrb ? memberDivAttrb.attrb : "NOM") === "NOM")
                                  .map(memberDiv => memberDiv.comCode)
                                  .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                                false
                    )
                ).length;
            obj["secondHalfWeekend"] =
                reservationInfos.filter(resveInfo =>
                    ["07","08","09","10","11","12"].includes(resveInfo.resveDate.substring(5, 7)) &&
                    resveInfo.bsnCode === "WEEKEND" &&
                    resveInfo.partDiv === partDiv.comCode &&
                    (
                      loop === 0 ?
                        true :
                        loop === 1 ?
                          resveChannelAttrbIdx1
                            .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                            .map(resveChannel => resveChannel.comCode)
                            .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                          memberDivAttrbIdx3
                            .filter(memberDivAttrb => memberDivAttrb.attrb === "MEMBER")
                            .map(memberDiv => memberDiv.comCode)
                            .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                          loop === 2 ?
                            resveChannelAttrbIdx1
                              .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                              .map(resveChannel => resveChannel.comCode)
                              .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                            memberDivAttrbIdx3
                              .filter(memberDivAttrb => memberDivAttrb.attrb === "COPR")
                              .map(memberDiv => memberDiv.comCode)
                              .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                            loop === 3 ?
                              resveChannelAttrbIdx1
                                .filter(resveChannelAttrb => resveChannelAttrb.attrb === "AGENT")
                                .map(resveChannel => resveChannel.comCode)
                                .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) :
                              loop === 4 ?
                                resveChannelAttrbIdx1
                                  .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                                  .map(resveChannel => resveChannel.comCode)
                                  .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                                memberDivAttrbIdx3
                                  .filter(memberDivAttrb => (memberDivAttrb.attrb ? memberDivAttrb.attrb : "NOM") === "NOM")
                                  .map(memberDiv => memberDiv.comCode)
                                  .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                                false
                    )
                ).length;
            obj["secondHalfTotal"] =
                reservationInfos.filter(resveInfo =>
                    ["07","08","09","10","11","12"].includes(resveInfo.resveDate.substring(5, 7)) &&
                    resveInfo.partDiv === partDiv.comCode &&
                    (
                      loop === 0 ?
                        true :
                        loop === 1 ?
                          resveChannelAttrbIdx1
                            .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                            .map(resveChannel => resveChannel.comCode)
                            .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                          memberDivAttrbIdx3
                            .filter(memberDivAttrb => memberDivAttrb.attrb === "MEMBER")
                            .map(memberDiv => memberDiv.comCode)
                            .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                          loop === 2 ?
                            resveChannelAttrbIdx1
                              .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                              .map(resveChannel => resveChannel.comCode)
                              .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                            memberDivAttrbIdx3
                              .filter(memberDivAttrb => memberDivAttrb.attrb === "COPR")
                              .map(memberDiv => memberDiv.comCode)
                              .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                            loop === 3 ?
                              resveChannelAttrbIdx1
                                .filter(resveChannelAttrb => resveChannelAttrb.attrb === "AGENT")
                                .map(resveChannel => resveChannel.comCode)
                                .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) :
                              loop === 4 ?
                                resveChannelAttrbIdx1
                                  .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                                  .map(resveChannel => resveChannel.comCode)
                                  .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                                memberDivAttrbIdx3
                                  .filter(memberDivAttrb => (memberDivAttrb.attrb ? memberDivAttrb.attrb : "NOM") === "NOM")
                                  .map(memberDiv => memberDiv.comCode)
                                  .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                                false
                    )
                ).length;
            obj["weekdayTotal"] =
                reservationInfos.filter(resveInfo =>
                    resveInfo.bsnCode === "WEEKDAY" &&
                    resveInfo.partDiv === partDiv.comCode &&
                    (
                      loop === 0 ?
                        true :
                        loop === 1 ?
                          resveChannelAttrbIdx1
                            .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                            .map(resveChannel => resveChannel.comCode)
                            .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                          memberDivAttrbIdx3
                            .filter(memberDivAttrb => memberDivAttrb.attrb === "MEMBER")
                            .map(memberDiv => memberDiv.comCode)
                            .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                          loop === 2 ?
                            resveChannelAttrbIdx1
                              .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                              .map(resveChannel => resveChannel.comCode)
                              .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                            memberDivAttrbIdx3
                              .filter(memberDivAttrb => memberDivAttrb.attrb === "COPR")
                              .map(memberDiv => memberDiv.comCode)
                              .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                            loop === 3 ?
                              resveChannelAttrbIdx1
                                .filter(resveChannelAttrb => resveChannelAttrb.attrb === "AGENT")
                                .map(resveChannel => resveChannel.comCode)
                                .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) :
                              loop === 4 ?
                                resveChannelAttrbIdx1
                                  .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                                  .map(resveChannel => resveChannel.comCode)
                                  .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                                memberDivAttrbIdx3
                                  .filter(memberDivAttrb => (memberDivAttrb.attrb ? memberDivAttrb.attrb : "NOM") === "NOM")
                                  .map(memberDiv => memberDiv.comCode)
                                  .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                                false
                    )
                ).length;
            obj["weekendTotal"] =
                reservationInfos.filter(resveInfo =>
                    resveInfo.bsnCode === "WEEKEND" &&
                    resveInfo.partDiv === partDiv.comCode &&
                    (
                      loop === 0 ?
                        true :
                        loop === 1 ?
                          resveChannelAttrbIdx1
                            .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                            .map(resveChannel => resveChannel.comCode)
                            .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                          memberDivAttrbIdx3
                            .filter(memberDivAttrb => memberDivAttrb.attrb === "MEMBER")
                            .map(memberDiv => memberDiv.comCode)
                            .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                          loop === 2 ?
                            resveChannelAttrbIdx1
                              .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                              .map(resveChannel => resveChannel.comCode)
                              .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                            memberDivAttrbIdx3
                              .filter(memberDivAttrb => memberDivAttrb.attrb === "COPR")
                              .map(memberDiv => memberDiv.comCode)
                              .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                            loop === 3 ?
                              resveChannelAttrbIdx1
                                .filter(resveChannelAttrb => resveChannelAttrb.attrb === "AGENT")
                                .map(resveChannel => resveChannel.comCode)
                                .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) :
                              loop === 4 ?
                                resveChannelAttrbIdx1
                                  .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                                  .map(resveChannel => resveChannel.comCode)
                                  .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                                memberDivAttrbIdx3
                                  .filter(memberDivAttrb => (memberDivAttrb.attrb ? memberDivAttrb.attrb : "NOM") === "NOM")
                                  .map(memberDiv => memberDiv.comCode)
                                  .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                                false
                    )
                ).length;
            obj["total"] =
                reservationInfos.filter(resveInfo =>
                    resveInfo.partDiv === partDiv.comCode &&
                    (
                      loop === 0 ?
                        true :
                        loop === 1 ?
                          resveChannelAttrbIdx1
                            .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                            .map(resveChannel => resveChannel.comCode)
                            .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                          memberDivAttrbIdx3
                            .filter(memberDivAttrb => memberDivAttrb.attrb === "MEMBER")
                            .map(memberDiv => memberDiv.comCode)
                            .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                          loop === 2 ?
                            resveChannelAttrbIdx1
                              .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                              .map(resveChannel => resveChannel.comCode)
                              .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                            memberDivAttrbIdx3
                              .filter(memberDivAttrb => memberDivAttrb.attrb === "COPR")
                              .map(memberDiv => memberDiv.comCode)
                              .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                            loop === 3 ?
                              resveChannelAttrbIdx1
                                .filter(resveChannelAttrb => resveChannelAttrb.attrb === "AGENT")
                                .map(resveChannel => resveChannel.comCode)
                                .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) :
                              loop === 4 ?
                                resveChannelAttrbIdx1
                                  .filter(resveChannelAttrb => resveChannelAttrb.attrb !== "AGENT")
                                  .map(resveChannel => resveChannel.comCode)
                                  .includes(resveInfo?.reservationConfirm?.reservationMember?.resveChannel) &&
                                memberDivAttrbIdx3
                                  .filter(memberDivAttrb => (memberDivAttrb.attrb ? memberDivAttrb.attrb : "NOM") === "NOM")
                                  .map(memberDiv => memberDiv.comCode)
                                  .includes(resveInfo?.reservationConfirm?.reservationMember?.memberDiv) :
                                false
                    )
                ).length;
          });
          monthlyReservationStatusByMemberTypes.push(obj);
        });
      });

      this.monthlyReservationStatusByMemberTypes = monthlyReservationStatusByMemberTypes;
      this.monthlyReservationStatusByMemberTypeLength = monthlyReservationStatusByMemberTypes.length;
      this.titleYear = this.searchOptions.year;

      this.monthlyReservationStatusByMemberTypesByTotal = {
        weekdayMonth01:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.weekdayMonth01)
            .reduce((acc, cur) => acc + cur, 0),
        weekdayMonth02:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.weekdayMonth02)
            .reduce((acc, cur) => acc + cur, 0),
        weekdayMonth03:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.weekdayMonth03)
            .reduce((acc, cur) => acc + cur, 0),
        weekdayMonth04:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.weekdayMonth04)
            .reduce((acc, cur) => acc + cur, 0),
        weekdayMonth05:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.weekdayMonth05)
            .reduce((acc, cur) => acc + cur, 0),
        weekdayMonth06:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.weekdayMonth06)
            .reduce((acc, cur) => acc + cur, 0),
        weekdayMonth07:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.weekdayMonth07)
            .reduce((acc, cur) => acc + cur, 0),
        weekdayMonth08:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.weekdayMonth08)
            .reduce((acc, cur) => acc + cur, 0),
        weekdayMonth09:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.weekdayMonth09)
            .reduce((acc, cur) => acc + cur, 0),
        weekdayMonth10:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.weekdayMonth10)
            .reduce((acc, cur) => acc + cur, 0),
        weekdayMonth11:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.weekdayMonth11)
            .reduce((acc, cur) => acc + cur, 0),
        weekdayMonth12:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.weekdayMonth12)
            .reduce((acc, cur) => acc + cur, 0),
        weekendMonth01:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.weekendMonth01)
            .reduce((acc, cur) => acc + cur, 0),
        weekendMonth02:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.weekendMonth02)
            .reduce((acc, cur) => acc + cur, 0),
        weekendMonth03:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.weekendMonth03)
            .reduce((acc, cur) => acc + cur, 0),
        weekendMonth04:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.weekendMonth04)
            .reduce((acc, cur) => acc + cur, 0),
        weekendMonth05:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.weekendMonth05)
            .reduce((acc, cur) => acc + cur, 0),
        weekendMonth06:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.weekendMonth06)
            .reduce((acc, cur) => acc + cur, 0),
        weekendMonth07:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.weekendMonth07)
            .reduce((acc, cur) => acc + cur, 0),
        weekendMonth08:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.weekendMonth08)
            .reduce((acc, cur) => acc + cur, 0),
        weekendMonth09:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.weekendMonth09)
            .reduce((acc, cur) => acc + cur, 0),
        weekendMonth10:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.weekendMonth10)
            .reduce((acc, cur) => acc + cur, 0),
        weekendMonth11:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.weekendMonth11)
            .reduce((acc, cur) => acc + cur, 0),
        weekendMonth12:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.weekendMonth12)
            .reduce((acc, cur) => acc + cur, 0),
        totalMonth01:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.totalMonth01)
            .reduce((acc, cur) => acc + cur, 0),
        totalMonth02:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.totalMonth02)
            .reduce((acc, cur) => acc + cur, 0),
        totalMonth03:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.totalMonth03)
            .reduce((acc, cur) => acc + cur, 0),
        totalMonth04:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.totalMonth04)
            .reduce((acc, cur) => acc + cur, 0),
        totalMonth05:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.totalMonth05)
            .reduce((acc, cur) => acc + cur, 0),
        totalMonth06:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.totalMonth06)
            .reduce((acc, cur) => acc + cur, 0),
        totalMonth07:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.totalMonth07)
            .reduce((acc, cur) => acc + cur, 0),
        totalMonth08:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.totalMonth08)
            .reduce((acc, cur) => acc + cur, 0),
        totalMonth09:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.totalMonth09)
            .reduce((acc, cur) => acc + cur, 0),
        totalMonth10:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.totalMonth10)
            .reduce((acc, cur) => acc + cur, 0),
        totalMonth11:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.totalMonth11)
            .reduce((acc, cur) => acc + cur, 0),
        totalMonth12:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.totalMonth12)
            .reduce((acc, cur) => acc + cur, 0),
        firstHalfWeekday:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.firstHalfWeekday)
            .reduce((acc, cur) => acc + cur, 0),
        firstHalfWeekend:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.firstHalfWeekend)
            .reduce((acc, cur) => acc + cur, 0),
        firstHalfTotal:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.firstHalfTotal)
            .reduce((acc, cur) => acc + cur, 0),
        secondHalfWeekday:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.secondHalfWeekday)
            .reduce((acc, cur) => acc + cur, 0),
        secondHalfWeekend:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.secondHalfWeekend)
            .reduce((acc, cur) => acc + cur, 0),
        secondHalfTotal:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.secondHalfTotal)
            .reduce((acc, cur) => acc + cur, 0),
        weekdayTotal:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.weekdayTotal)
            .reduce((acc, cur) => acc + cur, 0),
        weekendTotal:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.weekendTotal)
            .reduce((acc, cur) => acc + cur, 0),
        total:
          monthlyReservationStatusByMemberTypes
            .filter(item => !item.isPartDivNameVisible)
            .map(item => item.total)
            .reduce((acc, cur) => acc + cur, 0),
      };
    },
    print() {
      if (this.monthlyReservationStatusByMemberTypes.length < 1) {
        return this.errorToast(this.$t("report.popupMessage.noData"));
      }

      const reportData = {
        reportJson: {
          jsonData: {
            monthlyReservationStatusByMemberTypes: this.monthlyReservationStatusByMemberTypes,
            year: this.titleYear,
          },
          username: this.username,
          uniqueCode: this.$options.name,
        },
      };
      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
    onClickExcel() {
      this.$refs.bodyGrid.excelExport();
    },
    onBodyGridHeaderCellInfo(args) {
      const {
        node,
      } = args;

      node.style.paddingLeft = "4px";
      node.style.paddingRight = "4px";
    },
    onBodyGridQueryCellInfo(args) {
      const {
        cell,
        column: {field},
        data,
      } = args;

      if (data[field] === 0) {
        cell.innerText = "-";
      }

      if (field === "partDivName") {
        if (!data.isPartDivNameVisible) {
          cell.innerText = "";
        }
      }

      if (
          field &&
          (field.startsWith("firstHalf") ||
          field.startsWith("secondHalf") ||
          field.endsWith("total") ||
          field.endsWith("Total"))
      ) {
        cell.style.backgroundColor = "#fef2cb";
      }

      if (data.isPartDivNameVisible) {
        cell.style.borderBottom = "2px solid #e0e0e0";
      }
    },
  }
};
</script>
